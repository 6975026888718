import { Link } from "gatsby";
import React from "react";
import Button from "../components/common/button";

export default function NotFoundPage() {
  return (
    <div className="flex min-h-screen flex-col justify-between bg-background text-fontPrimary">
      <div className="container mx-auto my-auto flex flex-col items-center justify-center px-5">
        <div className="max-w-lg text-center">
          <h2 className="mb-8 text-9xl font-extrabold text-primary">500</h2>
          <p className="mb-5 text-2xl font-semibold text-fontPrimary md:text-3xl">
            Maaf, telah terjadi kesalahan internal
          </p>

          <p className="text-md mb-5 text-gray-300 md:text-lg">
            Kami minta maaf atas ketidaknyamanan yang ditimbulkan. Saat ini,
            sistem kami mengalami masalah teknis yang sedang kami perbaiki.
            Mohon bersabar dan coba lagi nanti.
          </p>

          <Link rel="noopener noreferrer" href="/" className="">
            <Button
              children={"Kembali ke halaman utama"}
              className={"bg-primary"}
            />
          </Link>
        </div>
      </div>
    </div>
  );
}
